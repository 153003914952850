import axios from 'axios'

const getRepos = async (username, page, perPage) => {
  try {
    const response = await axios.get(`https://api.github.com/users/${username}/repos?sort=updated&page=${page}&per_page=${perPage}`)
    return response.data
  } catch (err) {
    return Error('Error fetching repos')
  }
}

export default getRepos
