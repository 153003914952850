<template>
<button @click="toggleTheme" class="themeBtn">Light & Dark</button>
</template>

<script>

export default {
  data() {
    return {
      theme: 'default'
    }
  },
  methods: {
    setTheme(themeName) {
      if (themeName === 'dark') {
        document.documentElement.style.setProperty('--primary-color', '#001524');
        document.documentElement.style.setProperty('--secondary-color', '#445D48');
        document.documentElement.style.setProperty('--tertiary-color', '#D6CC99');
        document.documentElement.style.setProperty('--text-color', '#FDE5D4');
      } else {
        document.documentElement.style.setProperty('--primary-color', '#E5D9B6');
        document.documentElement.style.setProperty('--secondary-color', '#A4BE7B');
        document.documentElement.style.setProperty('--tertiary-color', '#5F8D4E');
        document.documentElement.style.setProperty('--text-color', '#001524');
      }
    },
    toggleTheme() {
      if (this.theme === 'default') {
        this.theme = 'dark';
      } else {
        this.theme = 'default';
      }
      this.setTheme(this.theme);
    }
  },
  mounted() {
    this.setTheme(this.theme);
  }
}

</script>

<style scoped>
.themeBtn {
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  margin: 16px;
  cursor: pointer;
}

.themeBtn:hover {
  background-color: var(--tertiary-color);
  color: var(--primary-color);
}
</style>