<!-- App.vue -->
<template>
  <div class="appDiv">
    <CoderSebHeader />
    <main>
      <CoderSebPresentation />
      <RepoCardContainer />
      <ContactForm />
    </main>
      <CoderSebFooter />
  </div>
  
</template>

<script>
import CoderSebFooter from './components/CoderSebFooter.vue'
import CoderSebHeader from './components/CoderSebHeader.vue'
import CoderSebPresentation from './components/CoderSebPresentation.vue'
import ContactForm from './components/ContactForm.vue'
import RepoCardContainer from './components/RepoCardContainer.vue'


export default {
  components: {
    CoderSebFooter,
    RepoCardContainer,
    CoderSebPresentation,
    CoderSebHeader,
    ContactForm
},
mounted() {
  document.title = 'CoderSeb';
}
}
</script>

<style scoped>
@import '@/assets/main.css';
.appDiv {
  width: 100%;
  margin: 0;
  padding: 1em 1em 0 1em;
  min-height: 100vh;
  text-align: center;
  color: var(--text-color);
  background-color: var(--primary-color);
}

main {
  min-height: 100%;
}
</style>
