<template>
  <footer class="footer">
    <div class="container">
      <p>&copy; {{ currentYear }} Sebastian Åkerblom. All rights reserved.</p>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
.footer {
  background-color: var(--primary-color);
  color: var(--text-color);
  position: relative;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.container {
  margin: auto;
  padding: 1em;
}

@media screen and (max-width: 780px) {
  .footer {
    font-size: .7em;
  }
}
</style>
