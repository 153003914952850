<template>
  <div class="socialMediaContainer">
    <a class="socialMediaLink" href="https://www.linkedin.com/in/sebastian%C3%A5kerblom/" target="_blank">
      <font-awesome-icon :icon="['fab', 'linkedin']" />
    </a>
    <a class="socialMediaLink" href="https://github.com/CoderSeb" target="_blank">
      <font-awesome-icon :icon="['fab', 'square-github']" />
    </a>
    <a class ="resumeLink socialMediaLink" href="pdf/resume.pdf" target="_blank" rel="noopener noreferrer">
    <p class="resumeLinkText">Resumé</p>
    <font-awesome-icon :icon="['fas', 'file-pdf']" />
  </a>
    
    
  </div>
</template>
<script>

</script>
<style scoped>

.socialMediaContainer {
  padding: .5em;
}

.socialMediaLink {
  text-decoration: none;
  color: var(--text-color);
  font-size: 40px;
  margin: 6px;
  display: inline-block;
  transition: all .1s;
  text-shadow: 4px 4px 4px var(--text-color);
}

.socialMediaLink:hover {
  color: var(--tertiary-color);
  text-shadow: 4px 4px 4px var(--text-color);
}

.socialMediaLink:active {
  color: var(--primary-color);
  text-shadow: 4px 4px 4px var(--text-color);
}

.resumeLink {
  text-decoration: none;
  color: var(--text-color);
  font-size: 35px;
  margin-top: -2px;
  transition: all .1s;
  float: right;
}

.resumeLink:hover {
  color: var(--tertiary-color);
  text-shadow: 1px 1px 1px var(--text-color);
}

.resumeLinkText {
  font-size: .35em;
  margin: 0 auto;
  padding: 0;
}

</style>