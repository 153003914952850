<template>
  <div class="pageTitleContainer">
    <h1 class="pageTitle">
      CoderSeb.dev
    </h1>
  </div>

</template>

<script>


</script>

<style scoped>

.pageTitleContainer {
  float: left;
}

.pageTitle {
  color: var(--text-color);
  opacity: .4;
  user-select: none;
}


</style>