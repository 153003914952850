<template>
  <div>
    <h2 class="repoContainerTitle">Github Repos</h2>
    <div class="btnContainer">
    <button class="pageBtn" @click="prevPage" :disabled="currentPage === 1"><font-awesome-icon :icon="['fas', 'arrow-left']" /> Previous</button>
    <button class="pageBtn" @click="nextPage" :disabled="noMoreRepos">Next <font-awesome-icon :icon="['fas', 'arrow-right']" /></button>
    <font-awesome-icon v-if="isLoading" class="iconSpinner" :icon="['fas', 'spinner']" spin-pulse />
  </div>
  <div class="cardContainer">
    <RepoCard v-for="repo in repos" :key="repo.id" :repo="repo"/>
  </div>
  </div>
</template>

<script>
import getRepos from '../scripts/getRepos.js'
import RepoCard from './RepoCard.vue'

export default {
  components: {
    RepoCard
  },
  data() {
    return {
      repos: [],
      currentPage: 1,
      noMoreRepos: false,
      isLoading: false,
      perPage: 4,
    }
  },
  methods: {
    async fetchRepos() {
      this.isLoading = true;
      let newRepos
      try {
        newRepos = await getRepos('CoderSeb', this.currentPage, this.perPage);
      } catch (err) {
        this.isLoading = false;
        return;
      }
      

      this.isLoading = false;
      if (newRepos.length < this.perPage) {
        this.noMoreRepos = true;
        this.repos = newRepos;
        return;
      }
      this.repos = newRepos;
      this.noMoreRepos = false;
    },
    nextPage() {
      this.currentPage++;
      this.fetchRepos();
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchRepos();
      }
    }
  },
  mounted() {
    this.fetchRepos();
  }
}
</script>

<style scoped>
.cardContainer {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 1em;
  padding: 1em;
  min-height: 100%;
  background-color: var(--secondary-color);
  border-radius: 10px;
  margin: .5em auto;
}
.repoContainerTitle {
  margin: 1em;
  font-size: 1.5em;
  color: var(--text-color);
}

@media screen and (max-width: 580px) {
  .cardContainer {
    grid-template-columns: 1fr;
  }
}
.pageBtn {
  background-color: var(--secondary-color);
  color: var(--text-color);
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  margin-right: 2px;
  font-size: .9em;
  cursor: pointer;
  transition: transform .1s;
}

.pageBtn:hover {
  transform: translateY(-2px);
  background-color: var(--tertiary-color);
}

.pageBtn:disabled {
  background-color: gray;
  color: black;
  cursor: not-allowed;
}

.btnContainer {
  display: flex;
  justify-content: left;
}

.iconSpinner {
  font-size: 1.3em;
  margin: auto 0 auto 8px;
  color: var(--text-color);
}

</style>