<template>
  <div class="card">
    <div class="card-header">
      <img :src="repo.owner.avatar_url" alt="Owner Avatar" class="avatar"/>
      <h3>{{ repo.name }}</h3>
    </div>
    <p class="repoDescription">{{ repo.description }}</p>
    <div class="card-info">
      <div v-if="repo.language">
        <p>Language: {{ repo.language }} <i :class="['devicon', `devicon-${repo.language.toLowerCase().replace(' ', '')}-plain`]"></i></p>
    </div>
      
      <p>Last Updated: {{ new Date(repo.updated_at).toLocaleDateString() }}</p>
    </div>
    <a class="card-link" :href="repo.html_url" target="_blank">Go to Repository</a>
  </div>
</template>

<script>
export default {
  props: {
    repo: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.card {
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: var(--primary-color);
  transition: transform .2s;
}

.card:hover {
  transform: translateY(-5px);
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 16px;
}

.card-info {
  display: flex;
  flex-direction: column;
  text-align: left;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 14px;
  color: var(--text-color);
}

.card-link {
  text-decoration: none;
  display: inline-block;
  margin-top: 16px;
  padding: 8px 16px;
  background-color: var(--tertiary-color);
  color: var(--primary-color);
  border-radius: 4px;
}

.repoDescription {
  padding: 1em;
}

.card-link:hover {
  background-color: var(--text-color);
}
</style>
