<template>
<div class="presentationContainer">
  <img class="profileImg" src="img/coderseb_profile.jpg" alt="AI image of Sebastian Åkerblom">
    <div>
      <h2>Hi, I'm Sebastian Åkerblom</h2>
  <p>I'm a web developer and a former healthcare worker based in Borås, Sweden.</p>
  <p>At 31 years old I finally made the choice to delve into programming and web development.</p>
  <p>Now here I am, with a bachelors degree in computer science, hungry for development adventures!</p>
    </div>
  <SocialMediaBar />
  <ThemeButton />
</div>
</template>

<script>

import SocialMediaBar from './SocialMediaBar.vue'
import ThemeButton from './ThemeButton.vue'

export default {
  components: {
    SocialMediaBar,
    ThemeButton
  }
}

</script>

<style scoped>
.presentationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.3rem;
  background: var(--secondary-color);
  color: var(--text-color);
  padding: 1em 1em 0 1em;
  margin: 45px auto 1em auto;
  border-radius: 10px;
}

.profileImg {
  width: 140px;
  height: 140px;
  border-radius: 50%;
}

@media screen and (max-width: 780px) {
  .presentationContainer {
    font-size: 1em;
    height: 100%;
    padding: 1em;
  }
}

@media screen and (max-width: 580px) {
  .presentationContainer {
    font-size: .90em;
    padding: .5em;
  }

  .profileImg {
    width: 100px;
    height: 100px;
  }
}
</style>