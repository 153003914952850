<template>
  <form @submit.prevent="handleSubmit" class="contactForm">
    <h2>Contact Me</h2>
    <div>
      <input id="name" class="inputField" maxlength="50" v-model="formData.name" required placeholder="Name..." />
    </div>
    <div>
      <input
        type="email"
        class="inputField"
        id="email"
        maxlength="50"
        v-model="formData.email"
        required
        placeholder="Email..."
      />
    </div>
    <div>
      <input
        id="subject"
        class="inputField"
        v-model="formData.subject"
        maxlength="100"
        required
        placeholder="Subject..."
      />
    </div>
    <div>
      <textarea
        id="message"
        class="inputText"
        v-model="formData.message"
        maxlength="500"
        required
        placeholder="Message..."
        :class="{ 'non-empty': isMessageNotEmpty }"
      ></textarea>
      <div class="charCount">{{ formData.message.length }}/500</div>
    </div>
    <div v-if="success && !error" class="success">Message sent successfully!</div>
    <div v-if="error && !success" class="error">
      There was a problem sending your message. Please check the fields and try again.
    </div>
    <button :class="{ disableBtn: success }" :disabled="success" type="submit" class="formBtn">
      Submit
    </button>
  </form>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        name: '',
        email: '',
        subject: '',
        message: ''
      },
      success: false,
      error: false
    }
  },
  computed: {
    isMessageNotEmpty() {
      return this.formData.message.trim() !== ''
    }
  },
  methods: {
    async handleSubmit() {
      try {
        let body = {
          name: this.formData.name,
          email: this.formData.email,
          subject: this.formData.subject,
          message: this.formData.message
        }
        console.log('Request:', body)
        const response = await fetch('https://formspree.io/f/xdoqkapo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        })

        if (response.ok) {
          this.formData.name = ''
          this.formData.email = ''
          this.formData.subject = ''
          this.formData.message = ''
          this.success = true
          this.error = false
        } else if (!response.ok) {
          this.success = false
          this.error = true
          throw new Error('Network response was not ok')
        }
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }
  }
}
</script>

<style scoped>
.contactForm {
  background-color: var(--secondary-color);
  color: var(--text-color);
  padding: 0.5em 15em;
  border-radius: 10px;
}

@media screen and (max-width: 1440px) {
  .contactForm {
    padding: 0.5em 20em;
  }
}

@media screen and (max-width: 1140px) {
  .contactForm {
    padding: 0.5em 5em;
  }
}

@media screen and (max-width: 720px) {
  .contactForm {
    padding: 0.5em 1em;
  }
}

.contactForm div {
  display: flex;
  flex-direction: column;
}

.inputField {
  padding: 0.5em;
  border-radius: 10px;
  box-shadow: 1px 1px 3px 1px var(--primary-color);
  margin: 0.5em 0;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: 2px solid var(--primary-color);
  outline: none;
  transition: all 0.2s ease-in-out;
}

.inputField:focus {
  box-shadow: none;
  border: 2px solid var(--text-color);
}

.inputText {
  padding: 0.5em;
  border-radius: 10px;
  box-shadow: 1px 1px 3px 1px var(--primary-color);
  margin: 0.5em 0;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: 2px solid var(--primary-color);
  outline: none;
  resize: none;
  transition: all 0.2s ease-in-out;
  height: 10em;
}

.inputText:focus {
  box-shadow: none;
  border: 2px solid var(--text-color);
}

.inputText.non-empty,
.inputText:focus {
  height: 15em;
}

.formBtn {
  padding: 0.5em;
  border-radius: 10px;
  margin: 0.5em auto;
  font-size: 1em;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
}

.formBtn:hover {
  background-color: var(--text-color);
  color: var(--primary-color);
  cursor: pointer;
}

.charCount {
  text-align: right;
  font-size: 0.8em;
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

.formBtn:active {
  background-color: var(--tertiary-color);
  color: var(--primary-color);
  cursor: pointer;
  transform: translateY(2px);
}

.success,
.error {
  font-weight: bold;
  padding: 0.5em;
  border-radius: 10px;
  text-align: center;
  margin: 0.5em auto;
}

.success {
  color: var(--primary-color);
  background-color: var(--tertiary-color);
}

.error {
  color: var(--text-color);
  background-color: rgb(239, 115, 115);
}

.disableBtn {
  background-color: rgb(89, 89, 89);
  color: rgb(27, 27, 27);
  cursor: not-allowed;
}
</style>
